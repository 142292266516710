import * as Sentry from '@sentry/browser'



export function initSentry() {
    let replaysSessionSampleRate
    let replaysOnErrorSampleRate

    if (process.env.NODE_ENV === 'alpha') {
        replaysSessionSampleRate = 0.0
        replaysOnErrorSampleRate = 1.0
    } else if (process.env.NODE_ENV === 'qa') {
        replaysOnErrorSampleRate = 0
        replaysOnErrorSampleRate = 1.0
    } else {
        replaysSessionSampleRate = 0
        replaysOnErrorSampleRate = 1.0
    }
    Sentry.init({
        dsn: "https://365f2705ba731f92c1babbc22706ecbb@o4505715912802304.ingest.sentry.io/4505715980500992",
        integrations: [
            new Sentry.Replay({
                beforeAddRecordingEvent: (event) => {
                    // TODO return null if we want to ignore any event (i.e log noise or PII (personally identifying information))
                    return event
                }
            }
            )
        ],
        environment: process.env.NODE_ENV,
        replaysSessionSampleRate,
        replaysOnErrorSampleRate
        // Session replay
        /* TODO consider these once we've sorted out error traces, keep an eye out for performance regressions
        integrations: [
                    new Sentry.BrowserTracing({
                        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                        tracePropagationTargets: ["localhost", "https:yourserver.io/api/"],
                    }),
                    new Sentry.Replay(),
                ],
                // Session Replay
                replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
                replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur. */
    });
}